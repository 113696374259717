.moment {
    display: flex;
}

.moment .main {
    background-color: white;
    max-width: 709px;
    border-radius: 12px;
    margin:22px 22px 0px 22px;
    overflow: hidden;
    padding: 40px;
}

.moment .m-join-btn {
    cursor: pointer;
    width: 105px;
    height: 36px;
    /* Style */

    border: 1px solid #2974EB;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.moment .circle-container {
    margin-top: 13px;
    margin-left: 22px;
    margin-right: 22px;
    padding: 40px;
    background-color: white;
    border-radius: 12px;
    max-width: 709px;
}

.moment .circle {
    padding: 10px;
    margin-top:11px;
    background-color: #f2f3f4;
    border-radius: 12px;
    max-width: 630px;
    min-width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.moment .circle img{
    width: 128px;
    height: 128px;
}

.circle-container .sub {
    padding-left: 22px;
}