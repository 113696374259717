.search-history{
    width: 258px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 15px;
    height:250px;
}
.search-history:-webkit-scrollbar {
    height: 0;
    width: 0;
    color: transparent;
  }
.search-item{
    width: 709px;
    border-radius: 8px;
    display: flex;
}
.search-his-header{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #A0A0A0;
}
.search-his-header>div:last-child,.search-his-item:hover{
    cursor: pointer;
}
.search-his{
    margin-top:23px;
}
.search-his-item{
    margin-bottom:20px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #777777;
}
.search-his-item>img{
    width: 17px;
    height: 17px;
    display: inline-block;
    margin-right: 7px;
}

.search-item{
    width:639px;
    padding:35px;
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom:15px;
}
.search-item>div>img{
    width: 90px;
    height: 90px;
    border-radius: 4px;
}
.search-item-r{
    margin-left: 14px;
    width:100%;
}
.search-item-r>div:first-child{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-top:3px;
}

.search-item-desc{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
}
.search-item-dl{
    width:420px;
}
.search-item-num{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #ABABAB;
    margin-top:7px;
}

.search-item-btn{
    width: 75px;
    height: 31px;
    border: 1px solid #2974EB;
    border-radius: 100px;
    text-align: center;
    line-height:31px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
     color: #2974EB;
}