.circle {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 46px 66px;
    margin: 15px;
    border-radius: 12px;
    min-width: 709px;
}

.circle .circle-icon {
    width: 110px;
    height: 110px;
}

.circle .circle-head {
    padding-bottom: 26px;
    border-bottom: 1px solid #e6e6e6;
}

.circle .circle-owner {
    margin-top: 27px;
}

.circle-owenr-head {
    width: 66px;
    height: 66px;
    border-radius:66px;
    margin-right: 10px;
}

.circle  .circle-section{
    margin-top: 27px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.24px;
    color: #000000;
}

.circle .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.24px;
    color: #000000;
    font-family: 'PingFang SC';
    font-style: normal;
}

.circle.sub {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.24px;

    color: #000000;
}


.circle .join-btn {
    cursor: pointer;
    width: 320px;
    height: 45px;
    left: 0px;
    top: 0px;

    /* Linear */

    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 45px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 81px;
}
.circle-info-sub {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-top:3px;
    color: #ABABAB;
}

.circle .price {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.24px;

    /* Style */

    color: #2974EB;
}