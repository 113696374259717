.live-box {
    padding: 11px;
}
.live-box img{
    width: 237px;
    height: 237px;
}

.stat {
    margin-left: auto;
}
.stat2 {
    margin-right: 20px;
}

.home-page {
    width: 1445px;
    
}
.home-main {
    /* Rectangle 2 */

    /* position: absolute;
    width: 1078px;
    height: 698px; */
    /* left: 0px;
    top: 0px; */
    width: 709px;
    /* height: 698px; */
    overflow: hidden;
}

.right-col {
    width: 288px;
    margin-top:26px;
    margin-right: 26px;
    background-color: white;
    height: 698px;
    border-radius: 12px;
}

.right-col-header {
    height: 83px;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #B75CFF 95.46%);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.right-col-header .title {
    font-size: 22px;
    line-height: 30.8px;
    color: white;
}


.post {
    flex-direction: row;
    display: flex;
    padding-bottom: 10px;
    border-bottom: #e6e6e6 solid 1px;
    padding-top: 10px;
    cursor: pointer;
}

.post .circle-img {
    width: 147px;
    height: 110px;
}

.post .info {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.post img {
    width: 16px;
    height: 16px;
}

.post .color-1 {
    color:#666;
}
.post .color-2 {
    color:#ababab;
}
.post-bottom {
    display: flex;
    justify-content: center;
    padding: 33px;
}
.post-bottom .btn {
    border: 1px solid #ABABAB;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    width: 315px;
    height: 43px;
}

img.refresh {
    width: 16px;
    height: 13px;
}