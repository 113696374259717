.mine-card-box{
    margin-top: 15px;
    margin-right:11px;
}
.mine-card-box>div:first-child{
    margin-bottom:15px;
}

.blankcard {
    display: flex;
    width:709px;
    background-color:white;
    height:400px;
    margin:15px;
    border-radius:12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #777;
}