.userprofile{
    width: 825px;
    padding: 33px 85px 49px 85px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 15px;
}

.user-avatar>img{
    width: 85px;
    height: 85px;
    border-radius:85px;
    overflow: hidden;
    object-fit: contain;
}

.user-avatar-btn{
    width: 88px;
    height: 32px;
    border-radius: 34px;
    border:1px solid #2A76EC;
    margin-left: 80px;
    font-size: 15px;
    text-align: center;
    line-height: 32px;
    color: #2A76EC;
    cursor: pointer;
}
.profile-info{
    margin-top:9px;
}
.profile-info>div:nth-child(1),.profile-info>div:nth-child(2),.profile-info>div:nth-child(3){
    height: 98px;
    border-bottom:1px solid #ECECEC ;
    width: 728px;
}

.profile-save-btn{
    cursor: pointer;;
    width: 88px;
    height: 32px;
    border: 1px solid #2974EB;
    border-radius: 34px;
    font-size: 15px;
    letter-spacing: -0.24px;
    color: #2974EB;
    text-align: center;
    line-height: 32px;
    margin-top: 49px;
}
.profile-label{
    width: 156px;
    color: #ABABAB;
    font-size: 16px;
}
.user-nick{
    width: 176px;
    height: 35px;
    background: #F2F3F4;
    border-radius: 6px;
    letter-spacing: -0.24px;
    color: #000000;
    border:none;
    outline: none;
    padding: 0px 11px;
}

.profile-motto{
    height: 100px;
    width: 572px;
    background: #F2F3F4;
    border-radius: 6px;
}
textarea::-webkit-scrollbar {
    height: 0;
    width: 0;
    color: transparent;
}
.profile-motto>textarea{
    background-color: transparent;
    border: none;
    outline: none;
    width: 550px;
    padding: 11px 11px;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: -0.24px;
    color: #000000;
    height:78px;
}
.motto{
    margin-top: 21px;
    padding-bottom: 20px;
    border-bottom:1px solid #ECECEC ;
}